import { CustomTextEditor } from '@Components/CustomTextEditor';
import { UploadBigFileButton } from '@Components/UploadBigFileButton';
import { UploadButton } from '@Components/UploadButton';
import { usePromise } from '@Hooks/promise';
import { AddMultipleKeywordsDialog } from '@Pages/pages/Modules/EditModule/ActivityInformation/components/AddMultipleKeywordsDialog';
import { firestore, storage } from '@Utils/config/firebase';
import { uploadResource } from '@Utils/import.utils';
import { sanitizeString } from '@Utils/string.utils';
import { useFormik } from 'formik';
import _, { uniqBy } from 'lodash';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import styled from 'styled-components';
import { MultiTextInput } from './components/MultiTextInput';

export type FreeTextActivityEditorProps = {
    formik: ReturnType<typeof useFormik<any>>;
};
export const FreeTextActivityEditor: React.FC<FreeTextActivityEditorProps> = (props) => {
    const [showAddMultiple, setShowAddMultiple] = useState<boolean | undefined>();

    const [onQuestionImageUpload, loadingQuestion] = usePromise(
        async (file: File, index: number, formik: ReturnType<typeof useFormik<any>>) => {
            const id = await uploadResource(file);

            const [c1, c2, c3, c4, c5] = formik.values.cover_image_url;
            const data = [c1, c2, c3, c4, c5];
            data[index] = id;

            formik.setFieldValue(`cover_image_url`, data, false);
        }
    );
    const [onCoverImageRemove, loadingCoverImageRemove] = usePromise(
        async (file_id: string, index: number) => {
            await firestore.collection('resources').delete(file_id);
            await storage.child(`resources/${file_id}`).delete();
            const data = [...props.formik.values.cover_image_url] ?? [null, null, null, null, null];
            data[index] = null;
            props.formik.setFieldValue(`cover_image_url`, data, false);
        },
        {
            success: 'Image correctement supprimée',
            pending: 'Suppression en cours...',
        }
    );

    const [onCoverVideoRemove, loadingCoverVideoRemove] = usePromise(
        async (file_id: string, index: number) => {
            await firestore.collection('resources').delete(file_id);
            await storage.child(`resources/${file_id}`).delete();
            const data = props.formik.values.cover_video_url ?? [null, null, null, null, null];
            data[index] = null;
            props.formik.setFieldValue(`cover_video_url`, data, false);
        },
        {
            success: 'Vidéo correctement supprimée',
            pending: 'Suppression en cours...',
        }
    );

    const onCoverVideoUpload = async (resource_id: string, index: number) => {
        const data = props.formik.values.cover_video_url ?? [null, null, null, null, null];
        data[index] = resource_id;
        props.formik.setFieldValue(`cover_video_url`, data, false);
    };

    function onNewKeyword() {
        props.formik.setFieldValue('keywords', [...props.formik.values.keywords, ''], false);
    }

    const addMultipleKeywords = (keywords: string[]) => {
        const dedup = uniqBy([...props.formik.values.keywords, ...keywords], (keyword) =>
            sanitizeString(keyword.toLowerCase())
        );
        props.formik.setFieldValue('keywords', dedup, false);
        setShowAddMultiple(false);
    };

    return (
        <div className="mt-4">
            {showAddMultiple && (
                <AddMultipleKeywordsDialog onSave={addMultipleKeywords} onHide={() => setShowAddMultiple(false)} />
            )}
            <div className="he-paragraph--medium gray-900">Question</div>
            <div className="mt-3">
                <CustomTextEditor placeholder="Contenu question" field={'question'} formik={props.formik} />
            </div>
            <div className="he-paragraph--medium gray-900 mt-4">Images d'illustrations</div>
            <div className="flex mt-3 align-items-center w-full gap-3">
                {_.range(0, 5).map((i) => {
                    return (
                        <UploadButton
                            key={i}
                            label="Ajouter une image"
                            icon={PrimeIcons.IMAGE}
                            loading={loadingQuestion || loadingCoverImageRemove}
                            className="flex-1"
                            accept={['image/png', 'image/jpg', 'image/jpeg']}
                            onRemove={(id) => id && onCoverImageRemove(id, i)}
                            onChange={(file) => onQuestionImageUpload(file, i, props.formik)}
                            file_id={props.formik.values.cover_image_url[i]}
                            buttonClassName="he-button--primary-nf--xs w-full"
                        />
                    );
                })}
            </div>
            <div className="he-paragraph--medium gray-900 mt-4">Vidéos d'illustrations</div>
            <div className="flex mt-3 align-items-center w-full gap-3">
                {_.range(0, 5).map((i) => {
                    return (
                        <UploadBigFileButton
                            key={i}
                            label="Ajouter une vidéo"
                            icon="pi pi-video"
                            loading={loadingQuestion || loadingCoverVideoRemove}
                            className="flex-1"
                            accept={['video/mp4', 'video/ogg', 'video/webm']}
                            onUploaded={(file) => onCoverVideoUpload(file, i)}
                            onRemove={(id) => id && onCoverVideoRemove(id, i)}
                            file_id={props.formik.values.cover_video_url?.[i]}
                            buttonClassName="he-button--primary-nf--xs w-full"
                        />
                    );
                })}
            </div>
            <div className="he-paragraph--medium gray-900 mt-4">Mots-clés</div>
            <div className="mt-3 he-paragraph--regular gray-500">
                <i className="pi pi-exclamation-circle mr-2" />
                Saisissez les mots-clés
            </div>
            <div className="mt-4">
                <div className="flex flex-column gap-3">
                    <div className="flex gap-3 align-items-center">
                        <Button className="he-button--secondary--xs" onClick={onNewKeyword}>
                            Ajouter un mot-clé <i className={PrimeIcons.PLUS} />
                        </Button>
                        <Button className="he-button--secondary-nf--xs w-max" onClick={() => setShowAddMultiple(true)}>
                            Ajouter un ou plusieurs mots-clés <i className={PrimeIcons.DOWNLOAD} />
                        </Button>
                    </div>
                    {props.formik.values.keywords.map((keyword: string, index: number) => {
                        return (
                            <div className="flex gap-3 align-items-center w-full">
                                <Button
                                    className="he-button--danger-nfb--md"
                                    onClick={() => {
                                        const data = [...props.formik.values.keywords];
                                        data.splice(index, 1);
                                        props.formik.setFieldValue('keywords', data, false);
                                    }}
                                >
                                    <i className={PrimeIcons.TRASH} />
                                </Button>
                                <LineTitle className="he-paragraph--regular text-gray-600 w-max">
                                    Mot clé #{index + 1}
                                </LineTitle>
                                <div key={index + 'keyword'} className="flex-1 flex gap-2 align-items-center flex-wrap">
                                    <MultiTextInput
                                        separator="$"
                                        placeholder={'Mot-clé #' + (index + 1)}
                                        value={keyword}
                                        onChange={(value) => {
                                            const data = [...props.formik.values.keywords];
                                            data[index] = sanitizeString(value);
                                            props.formik.setFieldValue('keywords', data, false);
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const LineTitle = styled.div`
    border-right: 1px solid #e0e0e0;
    padding: 4px 16px 4px 0;
    height: 100%;
`;
