import React from 'react';
import {CustomInput} from '@Components/CustomInput';
import {useFormik} from 'formik';
import {Checkbox} from 'primereact/checkbox';
import {CustomFormDropdown} from '@Components/CustomFormDropdown';
import _ from 'lodash';
import {Answer} from '@Types/Quizz';
import {produce} from 'immer';
import styled from 'styled-components';
import {RadioButton} from 'primereact/radiobutton';
import {UploadButton} from '@Components/UploadButton';
import {usePromise} from '@Hooks/promise';
import {firestore, storage} from '@Utils/config/firebase';
import {Resource} from '@Types/Resource';
import {v4} from 'uuid';
import {getDownloadURL} from 'firebase/storage';
import {uploadResource} from '@Utils/import.utils';
import {CustomTextEditor} from '@Components/CustomTextEditor';
import {InputSwitch} from 'primereact/inputswitch';
import {UploadBigFileButton} from "@Components/UploadBigFileButton";
import {PrimeIcons} from "primereact/api";

const AnswerContainer = styled.div`
  padding: 8px;
  background: #F1F5F9;

  &:not(:first-child) {
    margin-top: 8px;
  }
`;

export type QuizzActivityEditorProps = {
	formik: ReturnType<typeof useFormik<any>>,
}
export const QuizzActivityEditor: React.FC<QuizzActivityEditorProps> = (props) => {
	const onChangeResponseNumber = (n: number) => {
		const updatedArray = produce(props.formik.values.answers, (answers: Answer[]) => {
			const itemDiff = n - answers.length;
			if (itemDiff < 0) {
				answers.splice(n, -1 * itemDiff);
			} else {
				for (let i = 0; i < itemDiff; i++) {
					answers.push({
						is_response: false,
						text_or_image: ""
					});
				}
			}
			return answers;
		});
		props.formik.setFieldValue('answers', updatedArray, false)
	}

	const onUpdateField = (text: string, i: number) => {
		props.formik.setFieldValue(`answers.${i}.text_or_image`, text, false);
	}

	const onUpdateResponse = (checked: boolean, i: number) => {
		props.formik.setFieldValue(`answers.${i}.is_response`, !props.formik.values.answers[i].is_response, false);
	}
	const onCheckUpdateResponse = (checked: boolean, ai: number) => {
		const answers = produce(props.formik.values.answers, (answers: Answer[]) => {
			const res = answers.map((a, i) => ai == i ? ({
				text_or_image: a.text_or_image,
				is_response: true
			}) : {
				text_or_image: a.text_or_image,
				is_response: false
			});
			return res;
		});
		props.formik.setFieldValue('answers', answers, false);
	}

	const [onAnswerFileUpload, loading] = usePromise(async (file: File, answerIndex: number) => {
		const id = v4();
		const upload = await storage.put(file, `resources/${id}`);
		const url = await getDownloadURL(upload.ref);
		await firestore.collection<Resource>("resources").set(id, {
			resource_name: file.name,
			resource_url: url,
			size: file.size,
			resource_id: id
		});

		props.formik.setFieldValue(`answers.${answerIndex}.text_or_image`, id, false);
	}, {
		pending: "Envoi en cours...",
		success: "Fichier correctement enregistré !"
	});

	const [onFileRemove, loadingRemove] = usePromise(async (file_id: string, ai: number) => {
		await firestore.collection('resources').delete(file_id);
		await storage.child(`resources/${file_id}`).delete();
		props.formik.setFieldValue(`answers.${ai}.text_or_image`, null, false);
	});

	const [onCoverImageRemove, loadingCoverImageRemove] = usePromise(async (file_id: string, index: number) => {
		await firestore.collection('resources').delete(file_id);
		await storage.child(`resources/${file_id}`).delete();
		const data = [...props.formik.values.cover_image_url] ?? [null, null, null, null, null];
		data[index] = null;
		props.formik.setFieldValue(`cover_image_url`, data, false);
	}, {
		success: "Image correctement supprimée",
		pending: "Suppression en cours..."
	});

	const [onCoverVideoRemove, loadingCoverVideoRemove] = usePromise(async (file_id: string, index: number) => {
		await firestore.collection('resources').delete(file_id);
		await storage.child(`resources/${file_id}`).delete();
		const data = props.formik.values.cover_video_url ?? [null, null, null, null, null];
		data[index] = null;
		props.formik.setFieldValue(`cover_video_url`, data, false);
	}, {
		success: "Vidéo correctement supprimée",
		pending: "Suppression en cours..."
	});


	const [onQuestionImageUpload, loadingQuestion] = usePromise(async (file: File, index: number, formik: ReturnType<typeof useFormik<any>>) => {
		const id = await uploadResource(file);

		const [c1, c2, c3, c4, c5] = formik.values.cover_image_url;
		const data = [c1, c2, c3, c4, c5];
		data[index] = id;

		formik.setFieldValue(`cover_image_url`, data, false);
	})

	const onCoverVideoUpload = async (resource_id: string, index: number) => {
		const data = props.formik.values.cover_video_url ?? [null, null, null, null, null];
		data[index] = resource_id;
		props.formik.setFieldValue(`cover_video_url`, data, false);
	}
	return (
		<div className="mt-4">
			<div className="he-paragraph--medium gray-900">
				Question
			</div>
			<div className="mt-3">
				<CustomTextEditor placeholder="Contenu question" field={"question"} formik={props.formik}/>
			</div>
			<div className="he-paragraph--medium gray-900 mt-4">Images d'illustrations</div>
			<div className="flex mt-3 align-items-center w-full gap-3">
				{
					_.range(0, 5).map(i => {
						return <UploadButton
							key={i}
							label="Ajouter une image"
							icon={PrimeIcons.IMAGE}
							loading={loadingQuestion || loadingCoverImageRemove}
							className="flex-1"
							accept={["image/png", "image/jpg", "image/jpeg"]}
							onRemove={(id) => id && onCoverImageRemove(id, i)}
							onChange={(file) => onQuestionImageUpload(file, i, props.formik)}
							file_id={props.formik.values.cover_image_url[i]}
							buttonClassName="he-button--primary-nf--xs w-full"
						/>
					})
				}
			</div>
			<div className="he-paragraph--medium gray-900 mt-4">Vidéos d'illustrations</div>
			<div className="flex mt-3 align-items-center w-full gap-3">
				{
					_.range(0, 5).map(i => {
						return <UploadBigFileButton
							key={i}
							label="Ajouter une vidéo"
							icon="pi pi-video"
							loading={loadingQuestion || loadingCoverVideoRemove}
							className="flex-1"
                            accept={['video/mp4', 'video/ogg', 'video/webm']}
							onUploaded={(file) => onCoverVideoUpload(file, i)}
							onRemove={(id) => id && onCoverVideoRemove(id, i)}
							file_id={props.formik.values.cover_video_url?.[i]}
							buttonClassName="he-button--primary-nf--xs w-full"
						/>
					})
				}
			</div>
			<div className="he-paragraph--medium gray-900 mt-4">Réponses</div>
			<div className="mt-3">
				<CustomFormDropdown label="Nombre de réponses" options={_.range(2, 11).map(v => ({
					value: v,
					label: v.toString()
				}))} value={props.formik.values.answers.length} onChange={onChangeResponseNumber}/>
			</div>
			<div className="mt-3">
				<Checkbox
					id="is_response_image"
					checked={props.formik.values.is_response_img}
					onChange={(e) => props.formik.setFieldValue("is_response_img", e.checked)}
					className="mr-2"
				/>
				<label htmlFor="is_response_image" className="he-paragraph--regular gray-700"> Réponses en image</label>
			</div>
			<div className="mt-3 he-paragraph--regular gray-500">
				<i className="pi pi-exclamation-circle mr-2"/>
				Saisissez et sélectionnez la bonne réponse
			</div>
			<div className="mt-4">
				{props.formik.values.answers.map((a: Answer, i: number) => {

					return <AnswerContainer key={i} className="flex align-items-center gap-3">
						{
							props.formik.values.type === "mcq" ?
								<Checkbox checked={a.is_response}
										  onChange={(e) => onUpdateResponse(!!e.checked, i)}/>
								:
								<RadioButton
									value={a.is_response}
									checked={a.is_response}
									name="response"
									onChange={(e) => onCheckUpdateResponse(!!e.checked, i)}
								/>
						}
						{
							!props.formik.values.is_response_img ?
								<>
									<CustomInput
										value={a.text_or_image || ""}
										onChange={(text) => onUpdateField(text, i)}
										placeholder={!a.text_or_image ? "Complétez la réponse" : ""}
										className="flex-1"
									/>
									<div className='flex gap-2 align-items-center'>
										<label className={"he-paragraph--regular gray-500"}>Champ libre </label>
										<InputSwitch
											checked={!!a.other}
											onChange={(e) => props.formik.setFieldValue(`answers.${i}.other`, e.value, false)}
										/>
									</div>
								</>
								:
								<UploadButton
									label="Ajouter une image"
									buttonClassName="he-button--primary-nf--xs"
									file_id={a.text_or_image}
									showPreview
									accept={["image/png", "image/jpg", "image/jpeg"]}
									onChange={file => onAnswerFileUpload(file, i)}
									onRemove={() => a.text_or_image && onFileRemove(a.text_or_image, i)}
									loading={loading || loadingRemove}
									icon="pi pi-download"/>
						}
					</AnswerContainer>
				})}
			</div>
			<div className="mt-3">
				<CustomInput label="Détail de la réponse" field={"answer_explanation"} formik={props.formik}
							 placeholder="Aucun détail"/>
			</div>
		</div>
	)
}
